import { useState, useEffect } from 'react';
import {isAdmin} from '../utils/user';

export function useIsAdmin() {
  const [isAdminValue, setIsAdminValue] = useState(false);

  useEffect(() => {
    async function checkIsAdmin() {
      const result = await isAdmin(); // Assuming isAdmin is an async function
      setIsAdminValue(result);
    }

    checkIsAdmin();
  }, []);

  return isAdminValue;
}
