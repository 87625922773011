import React, { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { InfoOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { Button, Space } from "antd";

const StyledInput = styled(TextArea)`
  font-size: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > *:first-child {
    margin-right: 10px;
  }
`;

const isQueryValid = () => {
  const isMinLength = (value) => value.length > 15;

  return isMinLength;
};

const LawsSearch = ({ loading, onSearch, className }) => {
  const ref = useRef(null);

  const onSearchCallback = useCallback(() => {
    ref.current.resizableTextArea.textArea.textContent = "";
  }, [ref.current]);

  return (
    <>
      <Wrapper className={className}>
        <StyledInput
          ref={ref}
          placeholder="Pentru rezultate cât mai relevante, vă recomandam să formulați interogarea cât mai concisa și clară..."
          defaultValue="Ce trebuie să conțină o documentație pentru autorizația de construire?"
          disabled={loading}
          autoSize
          onKeyDown={(e) => {
            const value = ref.current.resizableTextArea.textArea.value?.trim();
            if (e.key === "Enter" && isQueryValid(value) && !loading) {
              onSearch(value, onSearchCallback);
            }
          }}
        />
        <Space />
        {!loading && (
          <Button
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => {
              onSearch(
                ref.current.resizableTextArea.textArea.value,
                onSearchCallback,
              );
            }}
          />
        )}
        {loading && <SyncOutlined spin size="large" />}
        <Space />
        {/*<Modal*/}
        {/*  title="Vertically centered modal dialog"*/}
        {/*  centered*/}
        {/*  open={modal2Open}*/}
        {/*  onOk={() => setModal2Open(false)}*/}
        {/*  onCancel={() => setModal2Open(false)}*/}
        {/*>*/}
        {/*  <p>some contents...</p>*/}
        {/*</Modal>*/}
      </Wrapper>
    </>
  );
};

export default LawsSearch;
