import React, {useEffect} from 'react';
import {ConfigProvider, message, theme} from 'antd';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Layout from './components/Layout';
import ro_RO from 'antd/es/locale/ro_RO';

Amplify.configure(awsconfig);

const App = ({ signOut, user }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content) => {
    messageApi.open({
      type: 'success',
      content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: 'error',
      content,
    });
  };

  const warning = (content) => {
    messageApi.open({
      type: 'warning',
      content,
    });
  };

  console.log(`> > > user: `, user);
  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
      }}
      locale={ro_RO}
    >
      {contextHolder}
      <Layout messages={{
          warning,
          error,
          success,
        }}
        auth={{ signOut, user  }}
      />
    </ConfigProvider>
  );
};
export default withAuthenticator(App, { hideSignUp: true }); // TODO enable signup when plans are avaiable
