import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'antd';
import {useSpring, animated} from 'react-spring';
import styled from 'styled-components/macro';
import LawsSearch from '../components/LawsSearch';
import {query} from '../services/api';
import {LawQueryResult} from '../components/LawQueryResult';

const ResultCard = styled(Card)`
  .ant-card-body {
    min-height: 160px;
    max-height: calc(75vh);
    overflow: auto;
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 1000px;
  min-width: 200px;

  @media (max-width: 980px) {
    width: 90vw;
  }

  ${({showResult}) => `justify-content: ${showResult ? 'start' : 'center'};`}
  ${({showResult}) => `
    justify-content: ${showResult ? 'start' : 'center'};
    ${showResult ? 'margin-top: 25px;' : ''}
  `}
`;

const AnimatedLawsSearch = styled(LawsSearch)`

  @media (max-width: 980px) {
    width: 100%;
    padding: 0 10px;
  }
`;


const Hint = styled.span`
  padding-left: 8px;
  color: #868686;
`;

function SearchPage({messages}) {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = useState(null)
  const [lastQuery, setLastQuery] = useState('')
  const [showCard, setShowCard] = useState(false);
  const props = useSpring({
    transform: !showCard ? "translateY(100px)" : "translateY(0px)",
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setShowCard(true)
      }, 1000);
    } else {
    }
  }, [loading]);

  const doSearch = async (value, cb) => {
    try {
      if (!value || !value.length) return;
      setLoading(true);
      setLastQuery(value);

      const res = await query(value);
      cb();

      setResult(res);
    } catch (err) {
      messages.error('There was a problem executing your query, please try again. ');

      console.error(err.message);
      console.error(err.stack);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page showResult={loading || lastQuery}>
      {(!result) && <Hint>
        Va recomandam sa nu folostiti date cu caracter personal în formarea interogării
      </Hint>}
      <AnimatedLawsSearch onSearch={doSearch} loading={loading}/>
      {
        showCard ? (
          <animated.div style={props}>
            <ResultCard loading={loading}>
              <LawQueryResult query={lastQuery}
                              strings={result?.summary}/>
            </ResultCard>
          </animated.div>
        ) : null
      }
    </Page>
  );
}

export default SearchPage;
