import React from "react";
import styled from "styled-components/macro";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import { Layout as AntDLayout, theme, Typography } from "antd";

import SearchPage from "../pages/SearchPage";
import ToSPage from "../pages/ToSPage";
import { NoMatchPage } from "../pages/NoMatchPage";
import { LoginPage } from "../pages/LoginPage";
import PrivacyPage from "../pages/PrivacyPage";
import { MainMenu } from "./MainMenu";

const { Header, Content, Footer } = AntDLayout;
const { Title } = Typography;

const StyledAntDLayout = styled(AntDLayout)`
  height: 100vh;
`;

const ContentWrapper = styled(Content)`
  padding: 0 50px;

  @media (max-width: 980px) {
    padding: 0 10px;
  }
`;

const ContentInner = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
`;

const MainTitle = styled(Title)`
  && {
    color: #f5f5f5;
    margin-left: 80px;
    width: 200px;
  }
`;

const End = styled.div`
  width: 87%;
  display: flex;
  justify-content: flex-end;
`;

const Version = styled.span`
  font-size: 9px;
  display: inline-block;
  position: absolute;
  right: 51px;
`;

const Gutter = styled.span`
  width: 10px;
  display: inline-block;
`;

function Layout({ messages, auth: { signOut, admin } }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Router>
      <StyledAntDLayout className="layout">
        <Header
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="demo-logo" />
          <Link to="/">
            <MainTitle>AI Lege</MainTitle>
          </Link>{" "}
          <Gutter />
          <End>
            <MainMenu signOut={signOut} />
          </End>
        </Header>

        <ContentWrapper>
          <ContentInner style={{ background: colorBgContainer }}>
            <Routes>
              <Route exact path="/login" element={<LoginPage />} />
              <Route
                exact
                path="/"
                element={<SearchPage messages={messages} />}
              />
              <Route exact path="/tos" element={<ToSPage />} />
              <Route exact path="/privacy" element={<PrivacyPage />} />
              <Route exact path="/admin" element={<ToSPage />} />


              <Route path="*" element={<NoMatchPage />} />
            </Routes>
          </ContentInner>
        </ContentWrapper>

        <Footer
          style={{
            textAlign: "center",
            padding: "5px",
            fontSize: "10px",
          }}
        >
          <Link to="/"> Acasa </Link>
          <Gutter />
          <Link to="/tos"> Termeni și condiții</Link>
          <Gutter />
          <Link to="/privacy"> Confidențialitate</Link>
          <br />
          AI Lege © 2023  |  Deferred Labs SRL
          <Version>
            Version: {process.env.REACT_APP_GIT_SHA || "Development"}
          </Version>
        </Footer>
      </StyledAntDLayout>
    </Router>
  );
}

export default Layout;
