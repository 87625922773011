import React from 'react';
import {Divider, Dropdown, Space} from 'antd';
import {DownOutlined, MenuOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {useIsAdmin} from '../hooks/isAdmin';


export function MainMenu({ signOut }) {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const items  = [
    isAdmin ?  {
      label: (
        <>
          <Link onClick={() => navigate('admin')}>Admin</Link>
          <Divider style={{ margin: 0 }} />
        </>
      ),
      key: '0',
    } : null,

    {
      label: (
        <Link  onClick={signOut}>Sign Out</Link>
      ),
      key: '5',
    },
  ].filter(Boolean);

  return (
    <Dropdown menu={{
        items,
        pointAtCenter: true
      }}
      style={{ width: '100px'}}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <MenuOutlined />
          <DownOutlined />
        </Space>
      </a>

    </Dropdown>
  );
}

