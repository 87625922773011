import React from "react";
import styled from "styled-components/macro";
import {Page} from './SearchPage';


export const Document = styled.div`
  padding: 10px 25px;
  max-height: 90vh;
  overflow: scroll;
  white-space: break-spaces;
`;

function ToSPage({ messages }) {
  return (
    <Page>
        <Document>
          <div >
            <p>
              <strong>
                Termeni și condiții pentru AILege.ro
              </strong>
            </p>
          </div>
          <div >
            <p>
              <strong>Ultima actualizare: 16 Oct 2023</strong>
            </p>
          </div>
          <div >
            <p>
              <strong>1. Introducere</strong>
            </p>
          </div>
          <div >
            <p>
              Bun venit la AILege.ro, operat de Deferred Labs SRL (“noi”, “ne” sau “al nostru”).
              Prin utilizarea aplicației noastre web (“Serviciu”), utilizatorii (“dvs.”) sunt de
              acord să fie legați de acești Termeni și Condiții (“ToS”).
            </p>
          </div>
          <div >
            <p>
              <strong>2. Descrierea Serviciului</strong>
            </p>
          </div>
          <div >
            <p>
              Serviciul nostru permite utilizatorilor să introducă interogări
              despre legislație și să primească răspunsuri. Serviciul este
              destinat exclusiv scopurilor informative și nu oferă consultanță
              juridică.
            </p>
          </div>
          <div >
            <p>
              <strong>3. Modificări ale ToS</strong>
            </p>
          </div>
          <div >
            <p>
              Putem actualiza acești ToS periodic. Modificările devin efective
              imediat după postare. Continuarea utilizării Serviciului după
              modificări indică acceptarea termenilor revizuiți.
            </p>
          </div>
          <div >
            <p>
              <strong>4. Utilizarea Serviciului</strong>
            </p>
          </div>
          <div >
            <p>
              a. Vă angajați să utilizați Serviciul numai în scopuri legale.
            </p>
          </div>
          <div >
            <p>
              b. Înțelegeți că răspunsurile furnizate de Serviciu se bazează pe
              informații generale și s-ar putea să nu fie exacte, complete sau
              aplicabile în circumstanțe specifice. Consultați întotdeauna un
              profesionist juridic calificat pentru consultanță juridică
              specifică.
            </p>
          </div>
          <div >
            <p>
              c. Orice utilizare abuzivă sau necorespunzătoare a Serviciului
              poate duce la suspendarea sau încetarea accesului dvs.
            </p>
          </div>
          <div >
            <p>
              <strong>5. Declinări de responsabilitate</strong>
            </p>
          </div>
          <div >
            <p>
              a. Serviciul este furnizat “așa cum este” și “după cum este
              disponibil”. Ne dezicem de toate garanțiile, exprese sau
              implicite, inclusiv precizia, completitudinea, adecvarea pentru un
              anumit scop sau neîncălcarea.a.
            </p>
          </div>
          <div >
            <p>
              b. Deferred Labs SRL nu este o firmă de avocatură, iar
              Serviciul nu oferă consultanță juridică. Orice informație obținută
              prin Serviciu nu trebuie folosită ca înlocuitor pentru sfatul unui
              avocat calificat.
            </p>
          </div>
          <div >
            <p>
              <strong>6. Limitarea Răspunderii</strong>
            </p>
          </div>
          <div >
            <p>
              În măsura maximă permisă de lege, Deferred Labs SRL nu va fi
              responsabilă pentru daune directe, indirecte, incidentale,
              speciale sau consecvente rezultate din sau legate de utilizarea
              sau incapacitatea de a utiliza Serviciul, chiar dacă am fost
              informați despre posibilitatea unor astfel de daune.
            </p>
          </div>
          <div >
            <p>
              <strong>7. Date și confidențialitate</strong>
            </p>
          </div>
          <div >
            <p>
              Prin utilizarea Serviciului, sunteți de acord cu colectarea și
              utilizarea informațiilor în conformitate cu Politica noastră de
              Confidențialitate. Valoarea noastră este confidențialitatea și ne
              străduim să protejăm orice date personale pe care ni le furnizați.
            </p>
          </div>
          <div >
            <p>
              <strong>8. Legea aplicabilă</strong>
            </p>
          </div>
          <div >
            <p>
              Acești ToS și orice litigii legate de ei sau de Serviciu vor fi
              guvernate de legile din Romania, fără a ține cont de principiile sale privind
              conflictele de legi.
            </p>
          </div>
          <div>
            <p>
              <strong>9. Contact</strong>
            </p>
          </div>
          <div>
            <p>
              Pentru orice întrebări sau preocupări legate de acești ToS, vă
              rugăm să ne contactați la [Adresa Dvs. de Email de Contact].
            </p>
          </div>
        </Document>
    </Page>
  );
}

export default ToSPage;
