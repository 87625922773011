import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClientProvider, QueryClient} from 'react-query';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

export const queryClient = new QueryClient();


console.log(`
Example query for demo & testing purposes
Ce este Ordinul Arhitecților din România?
Ce trebuie să facă un arhitect pentru a respecta legislația referitoare la urbanism?
Care sunt pașii pentru a trece un teren din extravilan în intravilan?
Care sunt obligațiile referitoare la siguranța la incendiu pentru o clădire de birouri?
`)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App/>
  </QueryClientProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
