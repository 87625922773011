import React, {useEffect, useMemo, useState} from 'react';
import {FileTextOutlined} from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import styled from 'styled-components/macro';
import LawLink from './LawLink';

const StyledCard = styled(Meta)`
  && {
    .d-none {
      visibility: hidden;
    }

    .Typewriter__wrapper {
      font-size: 14px;
      white-space: pre-wrap;
      color: #0b2f60;
    }
  }
`;

const Links = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
`;


// const customStringSplitter = (str) => {
//   const split = str.split(/(\b[\S\..]+\b)/g)
//
//   let skippingRefParts = false
//   const enforcedRefsSplit = split.reduce((acc, str, i, src) => {
//     const endsRefParts = str.includes(')')
//     if (skippingRefParts || endsRefParts) {
//       if (endsRefParts) {
//         skippingRefParts = false;
//       }
//       return acc;
//     }
//
//     if (str.endsWith(' (') && src[i + 1] === 'ref' && src[i + 2] === ': ') {
//       let combinedRef = `${str}${src[i + 1]}${src[i + 2]}`
//       skippingRefParts = true;
//
//       let j = i + 3;
//       do {
//         combinedRef += src[j];
//         j += 1;
//       } while (!src[j].startsWith(')'));
//
//       return [...acc, `.  ${combinedRef})\n\n`];
//     }
//
//     return [...acc, str];
//   }, [])
//
//   return enforcedRefsSplit;
// }

// const customNodeCreator = function (str) {
//   if (str.includes('(ref: ')) {
//     const wrapper = document.querySelectorAll('.Typewriter__wrapper')[0];
//     const articlesContent = Array.from(str.matchAll(/[\d\/]+/g));
//
//     articlesContent.forEach((articleContent, i) => {
//       const el = document.createElement('span');
//       el.textContent = `${articleContent} `
//       el.className = 'law-link d-none'
//       el['data-path'] = articleContent;
//       wrapper.appendChild(el)
//     });
//   }
//   return document.createTextNode(str);
// }

const format = (strings) => {
  let text = strings.reduce((acc, str, i, src) => {
    const match = str.match(/(\(ref: )(.*)\)/)
    const lawArticlePaths = match?.[2].split(', ') || [];

    if (lawArticlePaths.length) {
      const links = lawArticlePaths.map((path) => <LawLink asIcon={<FileTextOutlined />} path={path} />);
      const paragraph = str.replace(match[0], '');

      return [...acc, paragraph,<br/>, <Links>{links}</Links>]
    }
    return [...acc, str, <br/> ];
  }, [])

  return text;
}


export function LawQueryResult({query, strings}) {
  const [isTyping, setIsTyping] = useState(false)
  const [content, setContent] = useState();
  const [lawArticlePath, setLawArticlePath] = useState();

  useEffect(() => {
    const newContent = format(strings)
    setContent(newContent)
    setIsTyping(true)
  }, [strings]);

  useEffect(() => {
    if (isTyping) {
      setTimeout(() => setIsTyping(false), 4000);
    }
  }, [isTyping]);

  const cardBody = useMemo(() => {
    // if (isTyping) {
      /*return <Typewriter
        options={{
          delay: 10,
          stringSplitter: customStringSplitter,
          onCreateTextNode: customNodeCreator,
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString(content || 'Am întâmpinat o problemã, incercați din nou...')
            .start();
        }}
      />*/
    // } else {
      return (
        <div className="Typewriter__wrapper">
          {content}
        </div>
      )
    // }
  }, [isTyping]);

  if (!content) {
    return null;
  }

  return (
    <StyledCard
      title={query}
      description={cardBody}
    />
  );
}
